import React from 'react';
import { css } from '@emotion/core';
import { Layout, Main } from 'src/components/layout';
import { RootWrapper } from 'src/components/RootWrapper';
import { IndexHeader, IndexFooter } from 'src/components/pages/index';
import { QuienesSomosContent } from 'src/components/pages/quienes-somos/QuienesSomosContent';
import { TextContextProvider } from 'src/components/TextContext';
import { PersonasSection } from 'src/components/pages/index/PersonasSection';
import esTextCommon from '../../text/es/common.yaml';
import esTextIndex from '../../text/es/index.yaml';
import esTextQuienesSomos from '../../text/es/quienes-somos.yaml';
import enTextCommon from '../../text/en/common.yaml';
import enTextIndex from '../../text/en/index.yaml';
import enTextQuienesSomos from '../../text/en/quienes-somos.yaml';

const esText = { ...esTextCommon, ...esTextIndex, ...esTextQuienesSomos };
const enText = { ...enTextCommon, ...enTextIndex, ...enTextQuienesSomos };
const text = {
  es: esText,
  en: enText,
};

const QuienesSomosPage = ({ location }) => {
  return (
    <RootWrapper>
      <TextContextProvider text={text}>
        <Layout>
          <IndexHeader backgroundVisibility={1} location={location} />
          <Main
            css={css`
              margin-top: 6rem;
            `}
          >
            <QuienesSomosContent />
            <PersonasSection />
          </Main>
          <IndexFooter />
        </Layout>
      </TextContextProvider>
    </RootWrapper>
  );
};

export default QuienesSomosPage;
