import React from 'react';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { motion } from 'framer-motion';

// const variants = {
//   open: { y: "50%" },
//   closed: { y: "0%" },
// }

export const PersonaItem = ({
  name = '',
  title = '',
  detail = '',
  ...props
}) => (
  <div
    css={css`
      max-height: 20rem;
      max-width: 20rem;
      width: 23vw;
      height: 23vw;
      margin: 1rem;
      overflow: hidden;
      @media only screen and (max-width: 600px) {
        width: 43vw;
        height: 43vw;
      }
    `}
  >
    <div
      css={css`
        position: relative;
        display: flex;
      `}
    >
      <img
        {...props}
        css={css`
          object-fit: cover;
          width: 100%;
          height: 100%;
        `}
      />
      <div></div>
      <motion.div
        css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          text-align: center;
        `}
        whileHover={{ y: '-100%' }}
      >
        <div
          css={css`
            background: linear-gradient(
              rgb(255, 255, 255, 0) 60%,
              rgb(0, 0, 0, 0.6)
            );
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
          `}
        />
        <div
          css={css`
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-top: auto;
              position: absolute;
              bottom: 1rem;
              width: 100%;
            `}
          >
            <Typography
              variant="h6"
              css={css`
                color: white;
                margin: auto;
                text-align: center;
                @media (max-width: 30rem) {
                  font-size: 10px;
                }
              `}
            >
              {name}
            </Typography>
            <Typography
              css={css`
                color: white;
                margin: auto;
                text-align: center;
                font-weight: bold;
                @media (max-width: 30rem) {
                  font-size: 10px;
                }
              `}
            >
              {title}
            </Typography>
          </div>
          <div
            css={css`
              position: absolute;
              height: 100%;
              width: 100%;
              top: 100%;
              background-color: rgb(0, 0, 0, 0.6);
              display: flex;
              flex-direction: column;
            `}
          >
            <div
              css={css`
                margin: auto;
                padding: 1rem;
              `}
            >
              {detail.split('\n').map((x) => (
                <Typography
                  css={css`
                    color: white;
                    text-align: center;
                    @media (max-width: 30rem) {
                      font-size: 10px;
                    }
                  `}
                >
                  {x}
                </Typography>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  </div>
);

export default PersonaItem;
