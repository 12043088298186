import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Typography } from '@material-ui/core';
import { SectionHeader } from '../SectionHeader';
import { PersonaItem } from './PersonasItem';
import { SectionDivider } from '../SectionDivider';
import { useText } from 'src/components/TextContext';
import Carola from '../../../../../images/equipo/carola.jpg';
import Ingrid from '../../../../../images/equipo/ingrid.jpg';
import Karen from '../../../../../images/equipo/karen.jpg';

const PersonasContainer = styled.div`
  display: grid;
  margin: auto;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;
  justify-content: space-around;
  justify-items: center;
`;

export const PersonasSection = () => {
  const t = useText();
  return (
    <>
      <SectionHeader
        css={css`
          margin-top: 5rem;
        `}
      >
        {t.equipo.title}
      </SectionHeader>
      <div
        css={css`
          display: flex;
          margin-top: 1rem;
        `}
      >
        <Typography
          variant="h5"
          css={css`
            margin: auto;
            font-weight: bold;
            max-width: 100%;
          `}
        >
          {t.equipo.subtitle}
        </Typography>
      </div>
      <SectionDivider />
      <PersonasContainer>
        <div
          css={css`
            display: flex;
            max-width: 100%;
            justify-content: center;
            flex-wrap: wrap;
          `}
        >
          <PersonaItem
            src={Carola}
            name={t.equipo.carola.name}
            title={t.equipo.carola.title}
            detail={t?.equipo?.carola?.detail}
          />
          <PersonaItem
            src={Ingrid}
            name={t.equipo.ingrid.name}
            title={t.equipo.ingrid.title}
            detail={t.equipo.ingrid.detail}
          />
          <PersonaItem
            src={Karen}
            name={t.equipo.karen.name}
            title={t.equipo.karen.title}
            detail={t.equipo.karen.detail}
          />
        </div>
      </PersonasContainer>
    </>
  );
};

export default PersonasSection;
