import React from 'react';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { SectionHeader, SectionDivider } from 'src/components/pages/index';
import LocationImg from '../../../../images/location.png';
import { toParagraphsArray } from 'src/util';
import { useText } from 'src/components/TextContext';
import imgAdin from '../../../../images/logo/adin00.png';
import imgNipas from '../../../../images/logo/las-nipas00.png';

export const QuienesSomosContent = () => {
  const t = useText();
  const { content } = t.quienesSomos;
  const contentArr = toParagraphsArray(content);

  return (
    <div
      css={css`
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 1rem;
      `}
    >
      <SectionHeader>{t.quienesSomos.title}</SectionHeader>
      <SectionDivider />
      <div
        css={css`
          display: grid;
          margin: auto;
          padding: 0 1rem;
          @media (min-width: 70rem) {
            max-width: 70rem;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1.5cm;
            justify-content: center;
          }
        `}
      >
        <div>
          {contentArr.map((x, i) => (
            <Typography
              key={i}
              css={css`
                font-size: 25px;
                white-space: pre-wrap;
                margin: auto;
                text-align: justify;
                margin-bottom: 1rem;
              `}
            >
              {x}
            </Typography>
          ))}
          <div
            css={css`
              display: grid;
              grid-template-columns: 1fr 3rem 1fr;
              align-items: end;
              padding: 0 3rem;
            `}
          >
            <a
              href="https://www.adin.cl/"
              target="_blank"
              css={css`
                width: 100%;
              `}
            >
              <img
                src={imgAdin}
                css={css`
                  width: 100%;
                `}
              />
            </a>
            <div />
            <img
              src={imgNipas}
              css={css`
                width: 100%;
                margin-bottom: 6px;
              `}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
          `}
        >
          <img
            css={css`
              margin: auto;
              max-width: 100%;
              object-fit: scale-down;
            `}
            src={LocationImg}
          />
        </div>
      </div>
    </div>
  );
};

export default QuienesSomosContent;
